<template>
  <v-container 
    fluid 
    class="px-0"
  >
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      class="mb-5"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :footer-props="{
        itemsPerPageText: 'Linhas'
      }"
      :options="{
        itemsPerPage
      }"
      no-data-text="Nenhum registro importado"
    >
      <template v-slot:[`item.tabela`]="{ item }">
        {{ item.tabela | toUpperCase }}
      </template>
      <template v-slot:[`item.registros`]="{ item }">
        <a
          class="text-decoration-none"
          title="Clique aqui para visualizar os registros importados na entidade"
          @click="
            $router.push(item.link)
          "
          >{{ item.registros | parseNumberToIntegerBR }}</a
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: 'RegistrosPorTabelaDataTable',
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    items: {
      type: Array,
      default: []
    }
  },
  data: () => ({
    search: '',
    loading: false,
    headers: [
      {
        text: 'Tabela',
        value: 'tabela'
      },
      {
        text: 'Registros',
        value: 'registros'
      }
    ]
  })
};
</script>
